import { insertScript } from './trackingHelper'
import { trackingConfig } from '../../trackingConfig'

let enabled = false

export const loadGoogleTagManager = () => {
  if (enabled) {
    return
  }

  enabled = true

  // @ts-ignore
  window['dataLayer'] = (window['dataLayer'] || []) as any[]
  // @ts-ignore
  window['dataLayer'].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js',
  } as any)

  insertScript(
    `https://www.googletagmanager.com/gtm.js?id=${trackingConfig.googleTagManager.trackingId}`
  )
}
